export const imports = {
  'LICENSE.md': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "license" */ 'LICENSE.md'),
  'src/docs/home/index.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-home-index" */ 'src/docs/home/index.mdx'),
  'src/docs/layouts/index.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-layouts-index" */ 'src/docs/layouts/index.mdx'),
  'src/docs/documentation/general/getting-started.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-general-getting-started" */ 'src/docs/documentation/general/getting-started.mdx'),
  'src/docs/documentation/general/glossary.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-general-glossary" */ 'src/docs/documentation/general/glossary.mdx'),
  'src/docs/documentation/general/help.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-general-help" */ 'src/docs/documentation/general/help.mdx'),
  'src/docs/documentation/general/installation.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-general-installation" */ 'src/docs/documentation/general/installation.mdx'),
  'src/docs/documentation/general/introduction.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-general-introduction" */ 'src/docs/documentation/general/introduction.mdx'),
  'src/docs/documentation/general/license.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-general-license" */ 'src/docs/documentation/general/license.mdx'),
  'src/docs/documentation/general/manuscript.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-general-manuscript" */ 'src/docs/documentation/general/manuscript.mdx'),
  'src/docs/documentation/general/preprocessors.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-general-preprocessors" */ 'src/docs/documentation/general/preprocessors.mdx'),
  'src/docs/documentation/general/registration.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-general-registration" */ 'src/docs/documentation/general/registration.mdx'),
  'src/docs/documentation/general/requirements.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-general-requirements" */ 'src/docs/documentation/general/requirements.mdx'),
  'src/docs/documentation/general/structure.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-general-structure" */ 'src/docs/documentation/general/structure.mdx'),
  'src/docs/documentation/general/templates.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-general-templates" */ 'src/docs/documentation/general/templates.mdx'),
  'src/docs/documentation/license/blueoak.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-license-blueoak" */ 'src/docs/documentation/license/blueoak.mdx'),
  'src/docs/documentation/license/mit.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-license-mit" */ 'src/docs/documentation/license/mit.mdx'),
  'src/docs/documentation/references/pagination.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-references-pagination" */ 'src/docs/documentation/references/pagination.mdx'),
  'src/docs/documentation/references/strong-layouts.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-references-strong-layouts" */ 'src/docs/documentation/references/strong-layouts.mdx'),
}
